import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../../components/Layout'
import SEO from '../../../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import EstateImg from '../../../assets/images/estate-gates-img.jpg'

class EstateGates extends React.Component {
	render() {
		const siteTitle =
			'Entry & Estate Gates - Arlington, TX and Dallas-Fort Worth, TX'
		const siteDescription =
			'Get a Custom Estate Gate in Dallas-Fort Worth, TX. Call  - We Build Our Automatic Gates in Arlington, TX and Install Anywhere in DFW.'

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />

				<div className="container-fluid text-center headline gates-headline">
					<div className="container">
						<h1 className="text-white m-0">
							Entry & Estate Gates in Arlington, TX
						</h1>
						<hr />
					</div>
				</div>

				<div className="container d-padding-t d-padding-b access-sys">
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<p>
								A beautiful gate will not only enhance the value of your estate
								but make it more secure as well. If you need an entry gate
								installed or{' '}
								<Link to="/gates/services/gate-repair/">repaired</Link>, J & J
								Gates Service and Design has the expertise and is here to help!
								Contact us to learn more about our estate gates in Dallas and
								Fort Worth, TX at . We look forward to helping you find the
								perfect gate for your budget and needs!
							</p>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-8">
							<h4>Entry Gates Add Value & Security</h4>
							<p>
								Entry gates are the first thing someone sees when approaching
								your property. We all know how important first impressions are,
								and a beautiful gate also adds property value. At J & J Gates
								Service and Design, we carry a wide selection of gate styles,
								materials, and colors to help you find a look that suits your
								property. We design, program,{' '}
								<Link to="/gates/services/">install and repair</Link> estate
								gates in Dallas-Fort Worth and will do whatever it takes to make
								sure everything works properly and that you are completely
								satisfied.
							</p>
							<p>
								The best way to keep unwanted visitors off your property is with
								a properly functioning security gate. A security gate protecting
								your estate will send the message not to trespass and can also
								deter burglars. If security is your primary concern, your gate
								should be tall, strong, and possibly use other forms of security
								such as a video surveillance system, electrical wires, and
								spikes or barbed wire at the top.
							</p>
						</div>
						<div className="col-md-4 mt-5">
							<LazyLoadImage
								className="img-fluid mt-5"
								src={EstateImg}
								alt="Estate Gate"
							/>
						</div>
					</div>
					<div className="row flex-column-reverse flex-md-row">
						<div className="col-md-12">
							<h4>Free Estimates</h4>
							<p>
								Contact J & J Gates Service and Design at today if you need gate
								installation or repair in the Dallas-Fort Worth, TX area. We can
								help you design the most attractive gate for your property while
								maintaining the highest standards of security and reliability.
								If you’d like to own one of the best estate gates in the Dallas
								and Fort Worth area, contact us today for a free estimate!
							</p>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default EstateGates

export const EstateGatesPageQuery = graphql`
	query EstateGatesPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
